import { IntroText as IntroTextComponent } from 'components/IntroText';

import type { IntroText as TIntroText } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface IIntroText extends ISharedTemplateProps {
  templateProps: TIntroText;
}

export const IntroText = ({ templateProps, theme }: IIntroText) => {
  return <IntroTextComponent {...templateProps} theme={theme} />;
};

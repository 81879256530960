import { Text as Paragraph, Title } from '../../Typography';
import { Theme } from '../../../styles/getThemeConfig';

interface IIntroTextContext {
  theme?: Theme;
  description?: string | null;
  title?: string | null;
  superText?: string | null;
  titleTag?: string | null;
}

export const IntroTextContext = ({
  superText,
  title,
  titleTag,
  theme,
  description,
}: IIntroTextContext) => {
  return (
    <>
      {superText && (
        <Paragraph className="top-0" tag="sup" type="lead">
          {superText}
        </Paragraph>
      )}
      {title && (
        <Title
          className="pt-2.5 lg:pb-7.5 md:pb-5 sm:pb-5"
          tag={titleTag || 'div'}
          type={theme ? 'xl' : 'md'}
        >
          {title}
        </Title>
      )}
      {description && (
        <Paragraph data-test="text-description" tag="p" type="lg">
          {description}
        </Paragraph>
      )}
    </>
  );
};
